import theme from "@chakra-ui/theme"


const myTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      50: '#bcc4cc',
      100: '#4CA6FF',
      200: '#FF2E32',
      300: '#FFC601',
      400: '#38A169',
      500: '#2A2D34',
      600: '#f2f5f7',
      700: '#8B939C',
      800: '#318afb',
    }
  },
  fonts: {
    heading: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
    body: "Graphik,-apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
    mono: "SFMono-Regular,Menlo,Monaco,Consolas,\"Liberation Mono\",\"Courier New\",monospace"
  },
}

export default myTheme;
