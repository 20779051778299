module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"NPTVLJTDKVLHBHzUDyn55iBwbHhmpEBK","devKey":"Ve9ZPETrBqGx5Rp3SDfDWqCJHPdDFAYD","trackPage":true,"trackPageDelay":50,"manualLoad":true},
    }]
